import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import { StatesList } from "../../../../../shared/states.canada.constants";
import { zipCodeValidation } from "../../../../../../utils/ValidationRegex";
import {
  INVALID_US_PHONE_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const infoTextFields = [
  {
    props: {
      label: "Address",
      id: "address",
      name: "address",
    },
    validationProps: {},
    size: 12,
  },
  {
    props: {
      label: "Address 2",
      id: "address2",
      name: "address2",
    },
    validationProps: {},
    size: 12,
  },
  {
    size: 4,
    props: {
      label: "City",
      id: "city",
      name: "city",
    },
    validationProps: {
      maxLength: 50,
    },
  },
  {
    size: 4,
    options: StatesList,
    props: {
      label: "State",
      id: "state",
      name: "state",
    },
    validationProps: {
      required: REQUIRED_ERROR("State"),
    },
    type: FORM_TYPES.Select,
  },
  {
    size: 4,
    props: {
      label: "ZIP",
      id: "zip",
      name: "zip",
    },
    validationProps: {
      pattern: {
        value: zipCodeValidation,
        message: "Please enter a valid postal code",
      },
    },
  },
  {
    props: {
      label: "Phone Number",
      id: "phoneNumber",
      name: "phoneNumber",
    },
    validationProps: {
      maxLength: 20,
      required: REQUIRED_ERROR("Phone Number"),
      pattern: INVALID_US_PHONE_VALIDATION(),
    },
    size: 12,
  },
  {
    props: {
      label: "Start Date",
      id: "startDate",
      name: "startDate",
    },
    validationProps: {
      required: REQUIRED_ERROR("Start Date"),
    },
    type: FORM_TYPES.DatePicker,
    size: 12,
  },
  {
    type: "checkbox",
    props: {
      label: "Contract Returned",
      id: "contractReturned",
      name: "contractReturned",
    },
    validationProps: {},
    size: 12,
  },
  {
    size: 6,
    props: {
      label: "CDR Number",
      id: "cdrnumber",
      name: "cdrnumber",
    },
    validationProps: {},
  },
  {
    size: 6,
    props: {
      label: "CDR Expiration Date",
      id: "cdrexpirationDate",
      name: "cdrexpirationDate",
    },
    type: FORM_TYPES.DatePicker,
    validationProps: {},
  },
  {
    size: 6,
    props: {
      label: "Liability Expiration Date",
      id: "proLiabilityExpirationDate",
      name: "proLiabilityExpirationDate",
    },
    type: FORM_TYPES.DatePicker,
    validationProps: {},
  },
  {
    size: 6,
    props: {
      label: "Consulting Services Agreement",
      id: "csaexpirationDate",
      name: "csaexpirationDate",
    },
    type: FORM_TYPES.DatePicker,
    validationProps: {},
  },

  {
    props: {
      label: "Sage Vendor",
      id: "sageEmployeeId",
      name: "sageEmployeeId",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sage Vendor"),
    },
    size: 12,
  },
];
