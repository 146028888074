import {
  REQUIRED_ERROR,
  INVALID_US_PHONE_VALIDATION,
} from "utils/errorMessages";
import { zipCodeValidation } from "utils/ValidationRegex";
import { INVALID_MULTIPLE_EMAIL_VALIDATION } from "utils/errorMessages";
import { StatesList } from "../../../../../../shared/states.canada.constants";
import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";

export const VENDOR_FIELDS = [
  {
    size: 4,
    props: {
      label: "Vendor Name",
      id: "name",
      name: "name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Vendor Name"),
    },
  },
  {
    size: 4,
    props: {
      label: "Contact",
      id: "contact",
      name: "contact",
    },
    validationProps: {
      maxLength: {
        value: 200,
        message: "Maximum value should be 200 chars.",
      },
    },
  },
  {
    size: 4,
    props: {
      label: "Email",
      id: "emailAddress",
      name: "emailAddress",
    },
    validationProps: {
      required: REQUIRED_ERROR("Email"),
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
  },
  {
    size: 3,
    props: {
      label: "Address",
      id: "street",
      name: "street",
    },
  },
  {
    size: 3,
    props: {
      label: "City",
      id: "city",
      name: "city",
    },
  },
  {
    size: 3,
    type: "select",
    options: StatesList,
    props: {
      label: "State",
      id: "state",
      name: "state",
    },
  },
  {
    size: 3,
    props: {
      label: "ZIP",
      id: "zipcode",
      name: "zipcode",
    },
    validationProps: {
      pattern: {
        value: zipCodeValidation,
        message: "Please enter a valid postal code",
      },
    },
  },
  {
    size: 6,
    props: {
      label: "Phone Number",
      id: "phoneNumber",
      name: "phoneNumber",
    },
    validationProps: {
      pattern: INVALID_US_PHONE_VALIDATION(),
    },
  },
  {
    size: 6,
    props: {
      label: "Sage Customer Id",
      id: "sageCustomerId",
      name: "sageCustomerId",
    },
  },
  {
    props: {
      name: "billingPeriodId",
      id: "billingPeriodId",
      label: "Billing Period",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillingPeriodSelect",
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    size: 6,
    props: {
      label: "Vendor Code",
      id: "dssiidentifier",
      name: "dssiidentifier",
    },
  },
  {
    size: 6,
    props: {
      label: "Vendor File Identifier",
      id: "vendorFileIdentifier",
      name: "vendorFileIdentifier",
    },
  },
];
