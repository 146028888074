import {
  REQUIRED_ERROR,
  INVALID_MULTIPLE_EMAIL_VALIDATION,
} from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import { StatesList } from "../../../../../shared/states.canada.constants";
import { zipCodeValidation } from "../../../../../../utils/ValidationRegex";

export const CORPORATE_GROUP_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
      placeholder: "Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
    },
    size: 6,
  },
  {
    props: {
      name: "automaticRdreportEmail",
      id: "automaticRdreportEmail",
      label: "Report Emails",
      placeholder: "Report Emails",
    },
    validationProps: {
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
    size: 6,
  },
  {
    props: {
      name: "address",
      id: "address",
      label: "Address",
      placeholder: "Address",
    },
    validationProps: {
      required: REQUIRED_ERROR("Address"),
    },
    size: 6,
  },
  {
    props: {
      name: "sageCustomerId",
      id: "sageCustomerId",
      label: "Sage Customer Id",
      placeholder: "Sage Customer Id",
    },
    size: 6,
  },
  {
    props: {
      name: "city",
      id: "city",
      label: "City",
      placeholder: "City",
    },
    validationProps: {
      required: REQUIRED_ERROR("City"),
    },
    size: 6,
  },
  {
    props: {
      name: "dssiidentifier",
      id: "dssiidentifier",
      label: "Distributor Specific Identifier",
      placeholder: "Distributor Specific Identifier",
    },
    size: 6,
  },
  {
    props: {
      name: "state",
      id: "state",
      label: "Choose State",
    },
    size: 6,
    type: FORM_TYPES.Select,
    options: StatesList,
    validationProps: {
      required: REQUIRED_ERROR("State"),
    },
  },
  {
    props: {
      name: "hticontactUserId",
      id: "hticontactUserId",
      label: "Choose HTI Contact Person",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getCorporateGroupHTISelect",
  },
  {
    size: 6,
    props: {
      label: "ZIP",
      id: "zip",
      name: "zip",
    },
    validationProps: {
      required: REQUIRED_ERROR("Zip"),
      pattern: {
        value: zipCodeValidation,
        message: "Please enter a valid postal code",
      },
    },
  },
  {
    props: {
      name: "ehrproviderId",
      id: "ehrproviderId",
      label: "EHR Integration",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getCorporateGroupEHRSelect",
  },
  {
    props: {
      name: "billingPeriodId",
      id: "billingPeriodId",
      label: "Billing Period",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillingPeriodSelect",
  },
  {
    props: {
      name: "billingContactEmail",
      id: "billingContactEmail",
      label: "Billing Contact Email",
      placeholder: "Billing Contact Email",
    },
    validationProps: {
      required: REQUIRED_ERROR("Billing Contact Email"),
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
    size: 6,
  },
  {
    props: {
      name: "headerImageUrl",
      id: "headerImageUrl",
      label: "Header Banner Image URL",
      placeholder: "Header Banner Image URL",
    },
    size: 6,
  },
  {
    props: {
      name: "accessId",
      id: "accessId",
      label: "Access Id (leave blank if not known)",
      placeholder: "",
    },
    size: 6,
  },
  {
    props: {
      name: "headerLinkUrl",
      id: "headerLinkUrl",
      label: "Header Banner Link URL",
      placeholder: "Header Banner Link URL",
    },
    size: 6,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "showRdsignatureOnMenus",
      id: "showRdsignatureOnMenus",
      label: "Show RD Signature Line on WAAG and Spreadsheet",
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
];
