/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Constants/StatesList.md)
 */
export const StatesList = [
  {
    label: "Alberta",
    id: "AB",
  },
  {
    label: "British Columbia",
    id: "BC",
  },
  // {
  //   label: "American Samoa",
  //   id: "AS",
  // },
  {
    label: "Manitoba",
    id: "MB",
  },
  {
    label: "New Brunswick",
    id: "NB",
  },
  {
    label: "Newfoundland and Labrador",
    id: "NL",
  },
  {
    label: "Northwest Territories",
    id: "NT",
  },
  {
    label: "Nova Scotia",
    id: "NS",
  },
  {
    label: "Nunavut",
    id: "NU",
  },
  {
    label: "Ontario",
    id: "ON",
  },
  {
    label: "Prince Edward Island",
    id: "PE",
  },
  {
    label: "Quebec",
    id: "QC",
  },
  {
    label: "Saskatchewan",
    id: "SK",
  },
  {
    label: "Yukon",
    id: "YK",
  },
];
